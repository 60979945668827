import * as React from "react"
import { Link, graphql } from "gatsby"


import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { appendScript } from "../functions/appendScript"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  appendScript(` (function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
    .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
    n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
    (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
    ml('account', '103552');`)

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <span>{post.frontmatter.date}</span>
          <span> {' | Tags: '} 
            {post.frontmatter.tags.map(tag => {
              return(
                <span>
                    <Link to={`/blog/tag/${tag}/`}>
                      #{tag} 
                    </Link>{' '}
                </span>
                )
              })}
          </span>
        </header>
        <hr />
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        <hr />
        <footer>
          <Bio />
        </footer>
      </article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
      site {
        siteMetadata {
          title
        }
      }
      markdownRemark(id: { eq: $id }) {
        id
        excerpt(pruneLength: 160)
        html
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          tags
          description
        }
      }
      previous: markdownRemark(id: { eq: $previousPostId }) {
        fields {
          slug
        }
        frontmatter {
          title
        }
      }
      next: markdownRemark(id: { eq: $nextPostId }) {
        fields {
          slug
        }
        frontmatter {
          title
        }
      }
  }
`
